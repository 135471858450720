import { companyInfo } from '../globalVariable';

const messages = {
    Launch_your_Web_Application: 'Website for your business',
    Learn_More: '자세히',
    Our_Service: '서비스',
    Get_a_Quote: '견적상담',
    Portfolio: '포트폴리오',
    Contact_Us: '상담 및 문의',
    Contact_Sales: '문의',
    Call_Us_Directly_At: '다이렉트 연락처',
    Send_Us_Email_At: '이메일 주소',
    Your_Name: '성함',
    Email_or_Phone: '이메일 / 전화번호',
    Message: '메세지',
    Submit: '전송',
    Name: '이름',
    Email: '이메일',
    Phone: '전화번호',
    _home_subtitle: '식당, 마사지샵, 태권도, 미용실 등등.. 작은 사업장을 운영하시는 분들께 웹사이트를 간편 제작해드립니다',
    _home_subtitle2: '캐나다내 한인전문 업체입니다. 투명하고 정직하게 서비스를 제공 해드리겠습니다.',
    _home_about_title: '캐나다내 한인전문 웹에이젼시',
    _home_about_subtitle: `${companyInfo.name}는 최고의 서비스를 제공하기 위해 항상 기쁜 마음으로 고객님의 연락을 기다리고 있습니다`,
    _home_about_section_title: '웹/앱 애플리케이션 제작',
    _home_about_section_content: `식당, 마사지샵, 태권도, 미용실 등의 작은 사업장을 운영하시는 분들께 추천드립니다. 비즈니스 웹/앱 애플리케이션을 통해 브랜드 가치를 높이고, 한 단계 더 성장시킬 수 있도록 ${companyInfo.name}가 함께합니다.`,
    _home_testimonial_title: '커스터머 리뷰',
    _home_testimonial_subtitle: `${companyInfo.name}는 서비스 개선을 위해 고객의 다양한 의견에 열려있습니다. 생각을 나누어 주시면, 함께 고민하겠습니다.`,
    _our_service_subtitle: '도메인, 호스팅, 그리고 애플리케이션 제작까지 간편하게 All-In-One 서비스를 제공합니다',
    _our_service_section_subtitle: '문의 주시면 상담을 통해 투명하고 정직하게 가격 견적을 내어드립니다',
    _our_service_section_subtitle2: '<b>이미 웹사이트</b>를 가지고 계시고, 관리비가 많이 들어 비용을 낮추고 싶은 분들께도 상담 해드립니다',
    _our_service_plan_basic: '레스토랑, 바, 마사지샵등 가장 기본적인 웹사이트를 제공합니다. 레스토랑의 경우에는 QR코드를 이용한 메뉴 보기 기능도 포함됩니다.',
    _our_service_plan_business: 'Basic 플랜에서 좀 더 나아가 로그인, 고객관리, 이메일 알람등 몇몇 추가 기능이 필요한 경우에 적합한 플랜입니다.',
    _our_service_plan_custom: '좀 더 큰 사이즈의 웹애플리케이션을 제작할 때에는 자세한 요구사항에 대한 상담을 통해서 가격 견적을 내어드립니다',
    _our_service_list1: '무료 도메인 제공 가능 (옵션)',
    _our_service_list2: '최대 7페이지',
    _our_service_list3: '최대 15페이지',
    _our_service_list4: '컴퓨터, 태블릿, 스마트폰에 적합한 반응형 웹디자인',
    _our_service_list5: '서버 호스팅',
    _our_service_list6: '추가 커스토마이징 기능 최대 2개',
    _our_service_list7: '요구 사항에 따라 가격이 달라집니다. 자세한 내용은 상담을 통해서 견적을 내어드립니다',
    _portfolio_subtitle: '웹사이트 템플릿 예시',
    _portfolio_list_restaurant: '레스토랑',
    _portfolio_list_beauty: '뷰티 & 스파',
    _portfolio_list_hair_salon: '미용실',
    _portfolio_list_clinic: '클리닉',
    _portfolio_list_fitness: '피트니스',
    _portfolio_list_travel: '여행사',
    _contact_us_title: 'Thank you for getting in touch!',
    _contact_us_content: '문의해 주셔서 감사합니다. 빠른 시일내로 연락드리겠습니다. 좋은 하루 되세요!',
    TEST: '테스트'
}

const ko = {
    locale: 'ko',
    messages: messages
}
export default ko;