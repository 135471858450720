import { Card, Rate, Typography } from "antd";
import Avatar from 'react-avatar';

import './RatingCard.less';

const { Title, Paragraph } = Typography;

interface IProps {
    name: string; 
    title: string; 
    desc: string; 
    rate: number
}

function RatingCard(props: IProps) {
    const { name, title, desc, rate } = props;

    return (
        <div className='rating-card-container'>
            <Card
                hoverable
                cover={
                    <Avatar name={name} round={true} size="80" />
                }
                bodyStyle={{ padding: 12, textAlign: 'center' }}
            >
                <Card.Meta 
                    title={
                        <div className='review-card-title'>
                            <Title level={4}>{title}</Title>
                            <Rate disabled defaultValue={rate} />
                        </div>
                    } 
                    description={
                        <Paragraph
                            style={{ fontSize: 16, height: 76 }}
                            ellipsis={{ tooltip: desc, rows: 3 }}
                        >
                            { desc }
                        </Paragraph>
                    }
                />
            </Card>
        </div>
    )
}

export default RatingCard;