import { Typography } from 'antd';
import './HeaderBreadcrumbs.less';

const { Title } = Typography;

interface IProps {
    imgUrl: string;
    title: string;
    desc: string;
}

function HeaderBreadcrumbs(props: IProps) {
    const { imgUrl, title, desc } = props;
    
    return (
        <div className='header-breadscrumbs-container' >
            <div className='header-breadscrumbs-image' style={{ backgroundImage: `url("${imgUrl}")` }} >
                <div className='header-breadscrumbs-desc'>
                    <Title>{title}</Title>
                    <Title level={3}>{desc}</Title>
                </div>
            </div>
            
            <div className='header-breadscrumbs-mask' />
        </div>
    );
}

export default HeaderBreadcrumbs;