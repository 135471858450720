// import { useSelector } from "react-redux";

import MainLayout from "../layouts/MainLayout";
import HomeIntro from "../components/home/HomeIntro";
import HomeAbout from "../components/home/HomeAbout";
import HomeTestimonial from "../components/home/HomeTestimonial";
import { Button } from "antd";
import { ping } from "../api/apiCommon";
// import { RootState } from "../redux/reducers";

function Home() {
    // const isHomeReady = useSelector((state: RootState) => state.loadingReducer.isHomeReady);

    return (
        <MainLayout>
            <HomeIntro />
            <HomeAbout />
            <HomeTestimonial />
            <Button onClick={() => {
                ping();
            }}>Ping</Button>
        </MainLayout>
    );
}

export default Home;