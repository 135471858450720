import './Paper.less';

function Paper(props: any) {
    const { children } = props;

    return (
        <div className='paper-container'>
            { children }
        </div>
    );
}

export default Paper;