import { RootState } from '../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { LOCALE_LANGUAGE } from '../locale';
import { actSetLanguage } from '../redux/actions/configAction';
import './LanguageSelector.less';

function LanguageSelector() {
    const language = useSelector((state: RootState) => state.configReducer.language);
    const dispatch = useDispatch();

    return (
        <div 
            className='language-selector-container'
            onClick={() => {
                dispatch(actSetLanguage(language === LOCALE_LANGUAGE.ENGLISH ? LOCALE_LANGUAGE.KOREAN : LOCALE_LANGUAGE.ENGLISH))
            }}
        >
            <b>{ language === LOCALE_LANGUAGE.ENGLISH ? '한국어' : 'ENGLISH' }</b>
        </div>
    );
}

export default LanguageSelector;