import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Button, Form, Modal } from 'antd';
import axios from 'axios';
import Paper from './Paper';
import SectionTitle from './SectionTitle';

import './ContactForm.less';

const { TextArea } = Input;

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};

function validateEmail(email: string) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
    }
    
    return false;
}

function ContactForm() {
    const intl = useIntl();
    const [disableBtn, setDisableBtn] = useState(true);
    const [form] = Form.useForm();

    const onFinish = async (values: any) => {
        const { name, phone, email, message } = values;

        axios.post('https://1qez5r69gh.execute-api.ca-central-1.amazonaws.com/dev/aws/send-email-v2', {
            name,
            email,
            phone,
            note: message
        });

        Modal.success({ 
            title: intl.formatMessage({ id: '_contact_us_title' }),
            content: intl.formatMessage({ id: '_contact_us_content' } )
        });

        form.resetFields();
        setDisableBtn(true);
    };
    
    const onChange = () => {
        const name = form.getFieldValue('name');
        const email = form.getFieldValue('email');
        if (!name || !email || !validateEmail(email)) {
            setDisableBtn(true);
        } else {
            setDisableBtn(false);
        }
    }

    return (
        <Paper>
            <div className='contact-form-container'>
                <SectionTitle
                    title="Let’s talk about your project"
                    titleLevel={2}
                    subtitle="We have made it easy for clients to reach us and get their solutions weaved"
                />
                <div className='contact-form-items'>   
                    <Form {...layout} form={form} onFinish={onFinish} onChange={onChange}>
                        <Form.Item name='name' label={intl.formatMessage({ id: 'Name' })}>
                            <Input size="large" maxLength={20} />
                        </Form.Item>
                        <Form.Item name='email' label={intl.formatMessage({ id: 'Email' })}>
                            <Input size="large" maxLength={20} />
                        </Form.Item>
                        <Form.Item name='phone' label={intl.formatMessage({ id: 'Phone' })}>
                            <Input size="large" maxLength={20} />
                        </Form.Item>
                        <Form.Item name='message' label={intl.formatMessage({ id: 'Message' })}>
                            <TextArea rows={6} maxLength={500} />
                        </Form.Item>
                        <div className='contact-form-submit'>
                            <Button size="large" type="primary" htmlType="submit" disabled={disableBtn}>
                                <FormattedMessage id='Submit' />
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </Paper>
    );
}

export default ContactForm;