import { Typography } from 'antd';
import Icon from '@ant-design/icons';

import Paper from './Paper';

import './ContactInfoItem.less';

interface IProps {   
    title: string;
    info: string;
    icon: any;
    href?: string;
}

const { Title, Link } = Typography;

function ContactInfoItem(props: IProps) {
    const { title, info, icon, href } = props;
    
    return (
        <Paper>
            <div className="contact-info-container">
                <div>
                    <Icon className='contact-info-svg' component={icon} />
                </div>
                <div>
                    <Title level={4}>{title}</Title>
                    {
                        href ?
                        <Link href={href} target="_blank">
                            <span className='contact-info-link' dangerouslySetInnerHTML={{__html: info}}/>
                        </Link> :
                        <span className='contact-info-link' dangerouslySetInnerHTML={{__html: info}}/>
                    }
                </div>
            </div>
        </Paper>
    )
}

export default ContactInfoItem;
