import { useIntl } from 'react-intl';
import { Typography } from 'antd';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import SectionTitle from '../SectionTitle';
import RatingCard from '../RatingCard';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './HomeTestimonial.less';


const { Link } = Typography;

function HomeTestimonial() {
    const intl = useIntl();
    
    return (
        <div className="home-testimonial-container">
            <SectionTitle
                title={intl.formatMessage({ id: '_home_testimonial_title' })}
                subtitle={intl.formatMessage({ id: '_home_testimonial_subtitle' })}
            />
            <Swiper 
                className="testimonial-slider"
                modules={[Pagination]}
                spaceBetween={150}
                slidesPerView={1}
                centeredSlides
                loop
                pagination={{ dynamicBullets: true, clickable: true }}
                breakpoints= {{
                    1199:{ slidesPerView : 2 },
                    991:{ slidesPerView : 2 },
                    767:{ slidesPerView : 1 },
                    575:{ slidesPerView : 1 }
                }}
            >
                <SwiperSlide>
                    <Link href='https://www.happyballoon.com' target="_blank">
                        <RatingCard
                            name='Louise L'
                            title='Balloon Art'
                            desc="Our experience with the service was outstanding. The team's dedication to delivering top-notch results was evident throughout the process. Their prompt and efficient service, along with their meticulous cleanup, left us thoroughly impressed. We're delighted with the quality of work we received."
                            rate={5}
                        />    
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link href='http://www.leeswct.com' target="_blank">
                        <RatingCard
                            name='Kwangsub Ahn'
                            title='KS Ahn - Taekwondo'
                            desc="We are completely satisfied with the quality of service that we get. The staff was respectful, efficient, accommodating, cleaned up thoroughly."
                            rate={5}
                        />    
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link href='http://www.balzzak.com' target="_blank">
                        <RatingCard
                            name='Sangchul Lee'
                            title='이상철 - Restaurant & Bar'
                            desc="web4biz 덕분에 간편하게 레스토랑 홈페이지 만들었습니다. IT 관련 지식이 하나도 없어 어떻게 시작할지 막막했는데, 상담을 통해 저렴하고 간단하게 해결이 되었네요."
                            rate={5}
                        />            
                    </Link>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default HomeTestimonial
