import english from './en';
import korean from './ko';

export enum LOCALE_LANGUAGE {
    ENGLISH = 'en',
    KOREAN = 'ko'
}

export function getLocale(locale: LOCALE_LANGUAGE) {
    if (locale === LOCALE_LANGUAGE.KOREAN) {
        return korean;
    } else {
        return english;
    }
}