import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { useEffect } from 'react';

/**
 * This component is to define any pre set variables before rendering like user data, cookies and etc
 */
function Root(props: any) {
    const { children } = props;
    useEffect(() => {
        AOS.init({
            offset: 200,
            duration: 1000,
            once: true,
            easing: 'ease',
        });
        AOS.refresh();
    }, []);

    return (
        <div>
            { children }
        </div>
    );
}

export default Root;