import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Drawer, Image, Layout, Menu, Typography } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import './Header.less';
import { RootState } from '../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { LOCALE_LANGUAGE } from '../locale';
import LanguageSelector from './LanguageSelector';

const { Link } = Typography;

export function getMenuItems(lang: LOCALE_LANGUAGE) {
    return [
        {
            key: '/',
            label: lang === LOCALE_LANGUAGE.KOREAN ? '홈' : 'Home'
        },
        {
            key: '/our-service',
            label: lang === LOCALE_LANGUAGE.KOREAN ? '서비스' : 'Service'
        },
        {
            key: '/portfolio',
            label: lang === LOCALE_LANGUAGE.KOREAN ? '포트폴리오' : 'Portfolio'
        },
        {
            key: '/contact-us',
            label: lang === LOCALE_LANGUAGE.KOREAN ? '상담/문의' : 'Contact Us'
        },
    ]
}

function Header() {
    const language = useSelector((state: RootState) => state.configReducer.language);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [visible, setVisible] = useState(false);
    const [scroll, setScroll] = useState(0);

    const isHome = location.pathname === '/' ? true : false;
    const isSticky = isHome && scroll > 0 ? true : false;

    useEffect(() => {
        const handleScroll = () => {
            if (isHome) {
                setScroll(window.scrollY);    
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isHome]);

    const menuItems = getMenuItems(language);

    return (
        <div className='header-container'>
            <Layout.Header className={`${isSticky ? 'is-sticky' : ''}`}>
                <div className='header-pc-view'>
                    <div style={{ display: 'flex '}}>
                        <Link onClick={() => { navigate('/'); }}>
                            <Image
                                preview={false}
                            />
                        </Link>
                        <LanguageSelector />
                    </div>
                    <Menu
                        defaultSelectedKeys={[location.pathname]} 
                        mode="horizontal" 
                        items={menuItems} 
                        onClick={(e) => {
                            navigate(e.key);
                        }}
                    />
                </div>
                <div className='header-mobile-view'>
                    <Link onClick={() => { navigate('/'); }}>
                        <Image
                            preview={false}
                            width={160} 
                            src={'https://public-dns.s3.ca-central-1.amazonaws.com/net.web4biz/logo_h_white.png'}
                        />
                    </Link>
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                        <LanguageSelector />
                        <MenuOutlined style={{ color: 'white', marginLeft: 20 }} onClick={() => { setVisible(true) }} />
                    </div>
                    <Drawer width='75vw' className='header-mobile-drawer' placement="right" onClose={() => { setVisible(false)} } visible={visible}>
                        {
                            menuItems.map((item, index) => {
                                return <p key={index} onClick={() => { setVisible(false); navigate(item.key); }} style={{ fontSize: 18 }}>{item.label}</p>
                            })
                        }
                    </Drawer>
                </div>
            </Layout.Header>
        </div>
    );
}

export default Header;
