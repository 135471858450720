import { combineReducers } from 'redux';
import loadingReducer from './loadingReducer';
import configReducer from './configReducer';

const rootReducer = combineReducers({
    loadingReducer,
    configReducer
})

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;