import { LOCALE_LANGUAGE } from "../../locale";

export enum ActionTypes {
    IS_HOME_READY = 'gm/IS_HOME_READY',
    SET_LANGUAGE = 'config/SET_LANGUAGE'
}

interface SetIsHomeReadyAction {
    type: ActionTypes.IS_HOME_READY;
    payload: boolean
}

interface SetLanguageAction {
    type: ActionTypes.SET_LANGUAGE;
    payload: LOCALE_LANGUAGE
}

export type Action = SetIsHomeReadyAction | SetLanguageAction;