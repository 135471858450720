import { Layout } from 'antd';

import Header from '../components/Header';
import Footer from '../components/Footer';
import './MainLayout.less';

const { Content } = Layout;

function MainLayout(props: any) {
    const { children } = props;

    return (
        <Layout className='mainlayout-container'>
            <Header />
            <Content className='mainlayout-content'>
                { children }
            </Content>
            <Footer />
        </Layout>
    );
}

export default MainLayout;