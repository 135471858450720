import { useIntl } from 'react-intl';
import { Row, Col } from 'antd';
import { PhoneFilled, MailFilled } from '@ant-design/icons';

import BodyContainer from '../components/BodyContainer';
import MainLayout from '../layouts/MainLayout';
import { companyInfo } from '../globalVariable';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import ContactInfoItem from '../components/ContactInfoItem';
import ContactForm from '../components/ContactForm';
import './ContactUs.less';

function About() {
    const intl = useIntl();
    
    return (
        <MainLayout>
            <HeaderBreadcrumbs 
                imgUrl={`${process.env.PUBLIC_URL}/img/contact_us.jpg`}
                title={intl.formatMessage({ id: 'Contact_Us' })}
                desc='We love to hear from you!'
            />
            <BodyContainer>
                <div className='about-container'>
                    <Row gutter={[{ xs: 0, sm: 16 }, 16]} style={{ width: '100%'}}>
                        <Col sm={12} xs={24}>
                            <ContactInfoItem 
                                href={`tel:${companyInfo.phone}`}
                                title={intl.formatMessage({ id: 'Call_Us_Directly_At' })}
                                info='(+1) 418-520-9999' 
                                icon={PhoneFilled} 
                            />
                        </Col>
                        <Col sm={12} xs={24}>
                            <ContactInfoItem 
                                href={`mailto:${companyInfo.email}`}
                                title={intl.formatMessage({ id: 'Send_Us_Email_At' })}
                                info='ejekk14@gmail.com' 
                                icon={MailFilled} 
                            />
                        </Col>
                        <Col xs={24}>
                            <ContactForm />
                        </Col>
                    </Row>
                    
                </div>
            </BodyContainer>
        </MainLayout>
        
    );
}

export default About;