import { useNavigate } from "react-router-dom";
import { FormattedMessage } from 'react-intl';   // FormattedMessage
import { Button, Image } from 'antd';

import './HomeIntro.less';

function HomeIntro() {
    const navigate = useNavigate();

    return (
        <div className='home-intro-video'>
            <Image
                preview={false}
                src={'https://public-dns.s3.ca-central-1.amazonaws.com/net.web4biz/home.jpg'}
            />
            <div className='home-intro-mask' />
            <div className='home-intro-text'>
                <span 
                    className="home-intro-text-title" 
                    data-aos="fade-up"
                    data-aos-duration="1000"
                >
                    <FormattedMessage id='Launch_your_Web_Application' />
                </span>
                <span 
                    className="home-intro-text-subtitle"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                >
                    <FormattedMessage id='_home_subtitle' />
                </span>
                <span 
                    className="home-intro-text-content"
                    data-aos="fade-up"
                    data-aos-delay="500"
                    data-aos-duration="1000"
                >
                    <FormattedMessage id='_home_subtitle2' />
                </span>
                <div 
                    style={{ marginTop: 30 }}
                    data-aos="fade-up"
                    data-aos-delay="1000"
                    data-aos-duration="1000"
                >
                    <Button 
                        type='default' 
                        size='large' 
                        style={{ background: 'transparent', color: 'white' }}
                        onClick={() => { navigate('/our-service'); }}
                    >
                        <FormattedMessage id='Learn_More' />
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default HomeIntro;