import Cookies from 'universal-cookie';

import { LOCALE_LANGUAGE } from '../../locale';
import { ActionTypes, Action } from '../actions/actionTypes';

const cookies = new Cookies();

interface stateType {
    language: LOCALE_LANGUAGE
}

const initState = {
    language: cookies.get('lang') ? cookies.get('lang') : LOCALE_LANGUAGE.ENGLISH
}

const configReducer = (state: stateType = initState, action: Action) => {
    switch (action.type) {
        case ActionTypes.SET_LANGUAGE: 
            cookies.set('lang', action.payload, { path: '/' });
            return { ...state, language: action.payload }
        default:
            return state;
    }
}

export default configReducer;