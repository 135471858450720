import axios from "axios";

export async function ping() {
    try {
        const result = await axios.get('http://localhost:5000/common/ping');
        console.log(result);
    } catch (e) {
        console.log(e);
    }
    
    try {
        const result2 = await axios.get('http://35.183.76.66:5000/common/ping');
        console.log(result2);
    } catch (e) {
        console.log(e);
    }

    try {
        const result3 = await axios.get('https://35.183.76.66:5000/common/ping');
        console.log(result3);
    } catch (e) {
        console.log(e);
    }
}