import { Typography } from 'antd';
import './SectionTitle2.less';

const { Title } = Typography;

interface IProps {
    title: string;
    subtitle: string;
}

function SectionTitle2(props: IProps) {
    const { title, subtitle } = props;
    
    return (
        <div className='section-title2-container'>
            <Title className='sub-title' level={4}>{ subtitle }</Title>
            <Title level={2}>{title}</Title>
        </div>
    );
}

export default SectionTitle2;