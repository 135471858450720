import { ActionTypes, Action } from '../actions/actionTypes';

interface stateType {
    isHomeReady: boolean
}

const initState = {
    isHomeReady: false
}

const loadingReducer = (state: stateType = initState, action: Action) => {
    switch (action.type) {
        case ActionTypes.IS_HOME_READY: 
            return { ...state, isHomeReady: action.payload }
        default:
            return state;
    }
}

export default loadingReducer;