import { Typography, Image } from 'antd';
import { PhoneFilled, MailFilled } from '@ant-design/icons';

import { companyInfo } from '../globalVariable';
import { getMenuItems } from './Header';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/reducers';
import './Footer.less';

const { Paragraph, Link } = Typography;

function Footer() {
    const language = useSelector((state: RootState) => state.configReducer.language);
    const navigate = useNavigate();
    
    const menuItems = getMenuItems(language);
    return (
        <div className='footer-container'>
            <div className='footer-main-content'>
                <div className='footer-main-content-col1'>
                    <Image
                        preview={false}
                    />
                </div>
                <div className='footer-main-content-col2'>
                    <div className='footer-main-content-2'>
                        {
                            menuItems.map((item, index) => {
                                return (
                                    <Paragraph key={index} onClick={() => { navigate(item.key); }}>{item.label}</Paragraph>
                                )
                            })
                        }
                    </div>
                    <div className='footer-main-content-3'>
                        <div className='footer-contact'>
                            <Link href={`tel:${companyInfo.phone}`} target="_blank">
                                <Paragraph><PhoneFilled />{companyInfo.phone}</Paragraph>
                            </Link>
                            <Link href={`mailto:${companyInfo.email}`} target="_blank">
                                <Paragraph><MailFilled />{companyInfo.email}</Paragraph>
                            </Link>
                        </div>
                        <div className='footer-social'>
                            <Link href={companyInfo.kakao} target="_blank">
                                <Image
                                    preview={false}
                                    width={32}  
                                    src={process.env.PUBLIC_URL + '/img/kakaotalk.png'}
                                />
                            </Link>
                            <Link href={companyInfo.insta} target="_blank">
                                <Image
                                    preview={false}
                                    width={32}  
                                    src={process.env.PUBLIC_URL + '/img/instagram.png'}
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-copyright'>
                <span>© {new Date().getFullYear()} WEB4BIZ Inc. All Rights Reserved.</span>
                <span>K. Ahn</span>
            </div>
        </div>
        
    );
}

export default Footer;
