import './HomeContainer.less';

interface IProps {
    backgroundColor?: 'inherit' | 'white',
    children: any
}

function HomeContainer(props: IProps) {
    const { backgroundColor, children } = props;

    return (
        <div className='home-container' style={{ backgroundColor: backgroundColor ? backgroundColor : 'inherit' }}>
            { children }
        </div>
    );
}

export default HomeContainer;