import { companyInfo } from '../globalVariable';

const messages = {
    Launch_your_Web_Application: 'Website for your business',
    Learn_More: 'Learn More',
    Our_Service: 'Our Service',
    Get_a_Quote: 'Get a Quote',
    Portfolio: 'Portfolio',
    Contact_Us: 'Contact Us',
    Contact_Sales: 'Contact Sales',
    Call_Us_Directly_At: 'Call Us Directly At',
    Send_Us_Email_At: 'Send Us Email At',
    Your_Name: 'Your Name',
    Email_or_Phone: 'Email or Phone',
    Message: 'Message',
    Submit: 'Submit',
    Name: 'Name',
    Email: 'Email',
    Phone: 'Phone',
    _home_subtitle: 'We make websites for small business owners such as Restaurant, Spa, Taekwondo, Hair Salon, etc..',
    _home_subtitle2: 'We use leading web design tools and the latest technology to get your site up and running. We prioritize user experience, SEO, Fast page loading and mobile friendly design!',
    _home_about_title: `${companyInfo.name} is a full-service creative agency`,
    _home_about_subtitle: 'Our team of designers, developers and creatives are perfectionists who love what they do and love',
    _home_about_section_title: 'Web/Mobile Application Development',
    _home_about_section_content: `${companyInfo.name} delivers perfect customized Web/Mobile Application just for your business. Our vision, passion and ideas are matched with focus, expertise and flair`,
    _home_testimonial_title: 'What our customers are saying about our services',
    _home_testimonial_subtitle: 'Get your company heading in the right direction with our digital marketing strategist',
    _our_service_subtitle: 'We are All-In-One web design studio for small business owners',
    _our_service_section_subtitle: 'Choose the plan that\'s right for your business',
    _our_service_section_subtitle2: 'If you <b>already have a website</b> paying large amount, get a quote with us and pay less',
    _our_service_plan_basic: 'This plan is best for a small business owner who needs a simple website such as Bar, Spa, or Restaurant with QR Code Menu access',
    _our_service_plan_business: 'This plan is also for a small business owner who needs some custom features such as Login, CRM, email/sms inquriry, multi languages or etc..',
    _our_service_plan_custom: 'This plan is for medium size companies or organizations who need their own web application. We can discuss more detail for your requirement',
    _our_service_list1: 'Free Domain / Bring Your Own',
    _our_service_list2: 'Web pages around up to 7',
    _our_service_list3: 'Web pages around up to 15',
    _our_service_list4: 'Responsive Web Design (Desktop, Tablet and Mobile)',
    _our_service_list5: 'Server Hosting',
    _our_service_list6: 'Customized feature up to 2',
    _our_service_list7: 'Everything in Business features plus as you requires',
    _portfolio_subtitle: 'Here is some examples of website template',
    _portfolio_list_restaurant: 'Restaurant',
    _portfolio_list_beauty: 'Beauty & SPA',
    _portfolio_list_hair_salon: 'Hair Salon',
    _portfolio_list_clinic: 'Clinic',
    _portfolio_list_fitness: 'Fitness',
    _portfolio_list_travel: 'Travel & INN',
    _contact_us_title: 'Thank you for getting in touch!',
    _contact_us_content: 'We appreciate you contacting us. We will get back in touch with you soon. Have a great day!',
    TEST: 'test'
}

const en = {
    locale: 'en',
    messages: messages
}

export default en