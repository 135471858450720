import { useState, useEffect, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import Tilt from 'react-parallax-tilt';
import { Col, Row, Typography, Button, Image } from 'antd';
// @ts-ignore
import Parallax from 'parallax-js';
import SectionTitle from "../SectionTitle";
import './HomeAbout.less';
import SectionTitle2 from "../SectionTitle2";
import HomeContainer from "./HomeContainer";


const { Text } = Typography;

function HomeAbout() {
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);
    const intl = useIntl();
    const navigate = useNavigate();

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
        relativeInput: true,
        })
        
        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])

    return (
        <HomeContainer backgroundColor='white'>
            <div className='home-about-container'>
                <SectionTitle
                    title={intl.formatMessage({ id: '_home_about_title' })}
                    subtitle={intl.formatMessage({ id: '_home_about_subtitle' })}
                />
                <Row>
                    <Col md={14} sm={12} xs={24}>
                        <div 
                            className="image-area"
                            data-aos="fade-up"
                            data-aos-duration="500"
                        >
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <Image
                                        src='https://public-dns.s3.ca-central-1.amazonaws.com/net.web4biz/about1.jpg'
                                        preview={false}
                                    />
                                </Tilt>
                            </div>
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <Image
                                        src='https://public-dns.s3.ca-central-1.amazonaws.com/net.web4biz/about2.jpg'
                                        preview={false}
                                    />
                                </Tilt>
                            </div>
                            <div className="shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "img/shape-animation/about-shape-1.png"} alt="" /></span>
                            </div>
                        </div>
                    </Col>
                    <Col md={10} sm={12} xs={24} className='home-about-what-we-do'>
                        <div
                            data-aos="fade-up"
                            data-aos-delay="500"
                            data-aos-duration="500"
                        >
                            <SectionTitle2
                                title={intl.formatMessage({ id: '_home_about_section_title' })}
                                subtitle="WHAT WE DO"
                            />
                            <Text style={{ fontSize: 16 }}><FormattedMessage id='_home_about_section_content' /></Text>
                            <div className='home-about-what-we-do-btn'>
                                <Button type='primary' size='large' onClick={() => { navigate('/our-service'); }}>
                                    <FormattedMessage id='Learn_More' />
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </HomeContainer>
    )
}

export default HomeAbout;
