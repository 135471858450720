import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
// import { useSelector } from 'react-redux';

import { getLocale } from '../locale/index';
import { RootState } from '../redux/reducers';
// import { RootState } from '../redux/reducers';

const Localization = (props: any) => {
    const { children } = props;
    const language = useSelector((state: RootState) => state.configReducer.language);
    const currentLocale = getLocale(language);
    console.log('currentLocale');
    console.log(currentLocale.locale);
    return (
        <IntlProvider messages={currentLocale.messages} locale={currentLocale.locale}>
            { children }
        </IntlProvider>
    )
}

export default Localization