import { Typography } from 'antd';
import './SectionTitle.less';

const { Title } = Typography;

interface IProps {
    title: string;
    titleLevel?: 1 | 2 | 3 | 4 | 5
    subtitle?: string;
}

function SectionTitle(props: IProps) {
    const { title, titleLevel, subtitle } = props;
    
    return (
        <div className='section-title-container'>
            <Title level={titleLevel ? titleLevel : 1}>{title}</Title>
            {
                subtitle &&
                <Title level={4}>{ subtitle }</Title>
            }
        </div>
    );
}

export default SectionTitle;