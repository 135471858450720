import { useState } from 'react';
import { useIntl } from 'react-intl';
import ReactPlayer from 'react-player';
import { Card, Col, Row, Modal, Button } from 'antd';

import BodyContainer from '../components/BodyContainer';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import MainLayout from '../layouts/MainLayout';
import './Portfolio.less';

const { Meta } = Card;

interface IModalContent {
    title: string,
    url: string
}

function Portfolio() {
    const [isModalVisible, setIsModalVisible] = useState<IModalContent| undefined>(undefined);
    const intl = useIntl();
    
    return (
        <MainLayout>
            <HeaderBreadcrumbs 
                imgUrl={`${process.env.PUBLIC_URL}/img/portfolio.jpg`}
                title={intl.formatMessage({ id: 'Portfolio' })}
                desc={intl.formatMessage({ id: '_portfolio_subtitle' })}
            />
            <BodyContainer>
                <div className='portfolio-container'>
                    <Row gutter={[24, 24]}>
                        <Col md={8} sm={12} xs={24}>
                            <Card
                                hoverable
                                cover={<img alt="example" src={`${process.env.PUBLIC_URL}/img/portfolio/restaurant.jpg`} />}
                                onClick={() => { setIsModalVisible({
                                    title: 'Touche - Restaurant',
                                    url: `${process.env.PUBLIC_URL}/video/Touche - Demo.mp4`
                                }); }}
                            >
                                <Meta title={intl.formatMessage({ id: '_portfolio_list_restaurant' })} />
                            </Card>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                            <Card
                                hoverable
                                cover={<img alt="example" src={`${process.env.PUBLIC_URL}/img/portfolio/beauty-spa.jpg`} />}
                                onClick={() => { setIsModalVisible({
                                    title: 'SPA Center - Beauty & SPA',
                                    url: `${process.env.PUBLIC_URL}/video/SPA Center - Demo.mp4`
                                }); }}
                            >
                                <Meta title={intl.formatMessage({ id: '_portfolio_list_beauty' })} />
                            </Card>
                            
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                            <Card
                                hoverable
                                cover={<img alt="example" src={`${process.env.PUBLIC_URL}/img/portfolio/hair-salon.jpg`} />}
                                onClick={() => { setIsModalVisible({
                                    title: 'BarbeX - Hair Salon',
                                    url: `${process.env.PUBLIC_URL}/video/BarbeX - Demo.mp4`
                                }); }}
                            >
                                <Meta title={intl.formatMessage({ id: '_portfolio_list_hair_salon' })} />
                            </Card>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                            <Card
                                hoverable
                                cover={<img alt="example" src={`${process.env.PUBLIC_URL}/img/portfolio/health-center.jpg`} />}
                                onClick={() => { setIsModalVisible({
                                    title: 'Health Center - Clinic',
                                    url: `${process.env.PUBLIC_URL}/video/Health Center - Demo.mp4`
                                }); }}
                            >
                                <Meta title={intl.formatMessage({ id: '_portfolio_list_clinic' })} />
                            </Card>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                            <Card
                                hoverable
                                cover={<img alt="example" src={`${process.env.PUBLIC_URL}/img/portfolio/sports.jpg`} />}
                                onClick={() => { setIsModalVisible({
                                    title: 'Fox Pro - Sports',
                                    url: `${process.env.PUBLIC_URL}/video/Sports - Demo.mp4`
                                }); }}
                            >
                                <Meta title={intl.formatMessage({ id: '_portfolio_list_fitness' })} />
                            </Card>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                            <Card
                                hoverable
                                cover={<img alt="example" src={`${process.env.PUBLIC_URL}/img/portfolio/travel.jpg`} />}
                                onClick={() => { setIsModalVisible({
                                    title: 'Parador - Travel & INN Template',
                                    url: `${process.env.PUBLIC_URL}/video/Parador - Demo.mp4`
                                }); }}
                            >
                                <Meta title={intl.formatMessage({ id: '_portfolio_list_travel' })} />
                            </Card>
                        </Col>
                    </Row>
                </div>
            </BodyContainer>
            {
                isModalVisible &&
                <Modal 
                    title={isModalVisible.title}
                    width={'fit-content'}
                    footer={[
                        <Button onClick={() => { setIsModalVisible(undefined); }}>
                            Close
                        </Button>
                    ]}
                    visible={true}
                    onCancel={() => { setIsModalVisible(undefined); }}
                    className='portfolio-modal'
                >
                    <ReactPlayer 
                        width='100%' 
                        height='100%'
                        controls={true} 
                        config={{ 
                            file: { 
                                attributes: { 
                                    controlsList: 'nodownload'
                                } 
                            } 
                        }}
                        playing 
                        muted 
                        url={isModalVisible.url} 
                    />
                </Modal>
            }
        </MainLayout>
    );
}

export default Portfolio;