import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

import { clrGrey } from "../color";
import Paper from "./Paper";
import './PricingCard.less';

const { Text, Title, Paragraph } = Typography;

interface IProps {
    accentColor: string;
    plan: 'basic' | 'business' | 'custom',
    desc: string,
    price?: number,
    features?: string[],
    icon: IconDefinition
}

function PricingCard(props: IProps) {
    const { accentColor, plan, desc, price, features, icon } = props;
    const navigate = useNavigate();

    return (
        <div className='pricing-card-container'>
            <Paper>
                <div className='pricing-card-ptline'
                    style={{ backgroundColor: accentColor }}
                />
                <div className="pricing-card-content">
                    <FontAwesomeIcon icon={icon} />
                    <Title level={2}>{plan.toUpperCase()}</Title>
                    <Paragraph style={{ height: 120 }}>{desc}</Paragraph>
                    <div className="pricing-card-price">
                        {
                            price ? 
                            <>
                            <div>
                                <Title className='pricing-card-price-title' level={2}>${price}</Title> + tax
                            </div>
                            <Text style={{ color: clrGrey }}>per month, billed yearly</Text>
                            </> : 
                            <Title level={3}><FormattedMessage id='Get_a_Quote' /></Title>
                        }
                        
                    </div>
                    <Button size="large" type="primary" onClick={() => { navigate('/contact-us'); } }>
                        <FormattedMessage id='Contact_Sales' />
                    </Button>
                    <ul style={{ height: 132, marginTop: 50, marginBottom: 0 }}>
                        {   
                            features ? 
                            features.map((item, index) => (
                                <li key={index}>{item}</li>
                            )) : 
                            <li><FormattedMessage id='_our_service_list7' /></li>
                        }
                    </ul>
                </div>
            </Paper>
        </div>
    )
}

export default PricingCard;