import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Typography } from 'antd';
import { faCampground, faHouseChimney, faBuildingColumns } from "@fortawesome/free-solid-svg-icons";

import BodyContainer from '../components/BodyContainer';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import MainLayout from '../layouts/MainLayout';
import PricingCard from '../components/PricingCard';
import { clrFooter, clrSecondary } from '../color';
import './OurService.less';


const { Title } = Typography;

function OurService() {
    const intl = useIntl();
    
    return (
        <MainLayout>
            <HeaderBreadcrumbs 
                imgUrl={`${process.env.PUBLIC_URL}/img/office.jpg`}
                title={intl.formatMessage({ id: 'Our_Service' })}
                desc={intl.formatMessage({ id: '_our_service_subtitle' })}
            />
            <BodyContainer>
                <div className='our-service-container'>
                    <div className='our-service-title'>
                        <Title level={2} style={{ marginBottom: 10 }}>Simple, straight forward pricing</Title>
                        <Title level={4}><FormattedMessage id='_our_service_section_subtitle' /></Title>
                        <Title level={5}><span dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: '_our_service_section_subtitle2' })}} /></Title>
                    </div>
                    <div className='our-service-content'>
                        <Row gutter={[24, 24]}>
                            <Col sm={8} xs={24}>
                                <PricingCard 
                                    accentColor={clrSecondary} 
                                    plan='basic'
                                    price={35}
                                    desc={intl.formatMessage({ id: '_our_service_plan_basic' })}
                                    features={[
                                        `${intl.formatMessage({ id: '_our_service_list1' })}`,
                                        `${intl.formatMessage({ id: '_our_service_list2' })}`,
                                        `${intl.formatMessage({ id: '_our_service_list4' })}`,
                                        `${intl.formatMessage({ id: '_our_service_list5' })}`
                                    ]}
                                    icon={faCampground}
                                />
                            </Col>
                            <Col sm={8} xs={24}>
                                <PricingCard 
                                    accentColor={clrFooter} 
                                    plan='business'
                                    price={45}
                                    desc={intl.formatMessage({ id: '_our_service_plan_business' })}
                                    features={[
                                        `${intl.formatMessage({ id: '_our_service_list1' })}`,
                                        `${intl.formatMessage({ id: '_our_service_list3' })}`,
                                        `${intl.formatMessage({ id: '_our_service_list4' })}`,
                                        `${intl.formatMessage({ id: '_our_service_list5' })}`,
                                        `${intl.formatMessage({ id: '_our_service_list6' })}`
                                    ]}
                                    icon={faHouseChimney}
                                />
                            </Col>
                            <Col sm={8} xs={24}>
                                <PricingCard 
                                    accentColor={'white'} 
                                    plan='custom'
                                    desc={intl.formatMessage({ id: '_our_service_plan_custom' })}
                                    icon={faBuildingColumns}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </BodyContainer>
        </MainLayout>
    );
}

export default OurService;